<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="tenantType">
          <el-select
              v-model="query.tenantType"
              clearable
              placeholder="请选择-业务类型"
              class="width300"
          >
            <el-option label="停车订单" value="0"></el-option>
<!--            <el-option label="长租订单" value="2"></el-option>-->
          </el-select>
        </el-form-item>
        <!-- <el-form-item prop="timeFrame">
          <div class="xxd-input__wrap">
            <el-select placeholder="请选择">
              <el-option value="1" label="结算方"></el-option>
              <el-option value="2" label="车辆"></el-option>
              <el-option value="3" label="手机号码"></el-option>
              <el-option value="2" label="商户"></el-option>
            </el-select>
            <el-input placeholder="付款方（根据结算方名称查询）" />
          </div>
        </el-form-item> -->
        <el-form-item prop="plateNumber">
          <el-input
            v-model="query.plateNumber"
            placeholder="搜索车牌号"
            prefix-icon=""
            class="width300"
          ></el-input>
<!--          <NumplateSuggest v-model="query.plateNumber"-->
<!--                           class="width300"/>-->
        </el-form-item>
<!--        <el-form-item prop="userTel">-->
<!--          <el-input-->
<!--              v-model="query.userTel"-->
<!--              clearable-->
<!--              placeholder="请输入手机号"-->
<!--              prefix-icon=""-->
<!--              style="width: 260px"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="settlementId">-->
<!--          <Settlement v-model="query.settlementId" style="width: 260px"/>-->
<!--        </el-form-item>-->
        <el-form-item prop="parkingLotId">
          <SuggestParking v-model="query.parkingLotId"
                          class="width300"/>
        </el-form-item>
        <el-form-item prop="parkingLotType">
          <el-select
              v-model="query.parkingLotType"
              clearable
              placeholder="请选择车场类型"
              class="width300"
          >
            <el-option label="路外车场" value="0"></el-option>
            <el-option label="路内车场" value="1"></el-option>
<!--            <el-option label="地上停车场" value="2"></el-option>-->
<!--            <el-option label="地下停车场" value="3"></el-option>-->
<!--            <el-option label="小区停车场" value="4"></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item prop="channel">
          <el-select
              v-model="query.channel"
              clearable
              placeholder="请选择付款方式"
              class="width300"
          >
            <el-option label="微信" value="0"></el-option>
            <el-option label="支付宝" value="1"></el-option>
            <!--20221022 去掉农行 改为优惠券-->
            <!-- <el-option label="农行" value="2"></el-option> -->
<!--            <el-option label="优惠券" value="3"></el-option>-->
          </el-select>
        </el-form-item>
<!--        <el-form-item prop="settlementChannel">-->
<!--          <el-select-->
<!--              v-model="query.settlementChannel"-->
<!--              clearable-->
<!--              placeholder="请选择结算通道"-->
<!--              style="width: 260px"-->
<!--          >-->
<!--            <el-option label="自有通道" value="1"></el-option>-->
<!--            <el-option label="第三方通道" value="2"></el-option>-->
<!--            <el-option label="银联通道" value="3"></el-option>-->
<!--            <el-option label="其它" value="4"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="paymentType">-->
<!--          <el-select-->
<!--              v-model="query.paymentType"-->
<!--              clearable-->
<!--              placeholder="请选择支付类型"-->
<!--              style="width: 260px"-->
<!--          >-->
<!--            <el-option label="支付" value="1"></el-option>-->
<!--            <el-option label="余额" value="2"></el-option>-->
<!--            &lt;!&ndash; <el-option label="虚拟优惠券" value="3"></el-option>-->
<!--            <el-option label="代金券" value="4"></el-option>-->
<!--            <el-option label="即停豆" value="5"></el-option> &ndash;&gt;-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="sourcePort">-->
<!--          <el-select-->
<!--              v-model="query.sourcePort"-->
<!--              clearable-->
<!--              placeholder="请选择-来源端"-->
<!--              style="width: 260px"-->
<!--          >-->
<!--            <el-option label="IOS" value="1"></el-option>-->
<!--            <el-option label="android" value="2"></el-option>-->
<!--            <el-option label="巡检充值" value="3"></el-option>-->
<!--            <el-option label="其他" value="4"></el-option>-->
<!--            <el-option label="app端的H5" value="5"></el-option>-->
<!--            <el-option label="商户端" value="6"></el-option>-->
<!--            <el-option label="用户端" value="7"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item prop="tradeStatus">
          <el-select
              v-model="query.tradeStatus"
              clearable
              placeholder="请选择交易状态"
              class="width300"
          >
            <el-option label="未支付" value="0"></el-option>
            <el-option label="交易成功" value="1"></el-option>
<!--            <el-option label="已退款" value="2"></el-option>-->
<!--            <el-option label="退款中" value="3"></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingOrderIntoId">
          <el-input
              v-model="query.parkingOrderIntoId"
              clearable
              placeholder="请输入业务订单号"
              class="width300"
          ></el-input>
        </el-form-item>
        <el-form-item prop="outTradeNo">
          <el-input
              v-model="query.outTradeNo"
              clearable
              placeholder="请输入第三方流水号"
              class="width300"
          ></el-input>
        </el-form-item>
<!--        <el-form-item prop="settlementType">-->
<!--          <el-select-->
<!--              v-model="query.settlementType"-->
<!--              clearable-->
<!--              placeholder="请选择结算方式"-->
<!--              style="width: 260px"-->
<!--          >-->
<!--            <el-option label="平台结算" value="1"></el-option>-->
<!--            <el-option label="独立结算" value="2"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="searchDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
        <div class="content-item">
          <div class="title">交易金额：</div>
          <div class="text">{{ parseFloat(totalMoney).toFixed(2) }}</div>
        </div>
        <div class="content-item">
          <div class="title">手续费合计：</div>
          <div class="text">{{ parseFloat(feeAmount).toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="付款方" width="120">
            <template slot-scope="scope">
              <Numplate :type="scope.row.color">{{
                  scope.row.plateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column align="center" label="业务类型" width="120">
            <template slot-scope="scope">
              <span>{{
                  scope.row.tenantType === 0 ? "停车订单" : "长租订单"
                }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--              align="center"-->
<!--              label="结算方"-->
<!--              prop="settlementName"-->
<!--              width="180"-->
<!--          >-->
<!--          </el-table-column>-->
          <el-table-column
              align="center"
              label="车场名称"
              prop="parkingLotName"
              width="150"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="订单金额"
              prop="orderAmount"
              width="100"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.receivableAmount).toFixed(2) }}
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="结算通道" width="140">-->
<!--            <template slot-scope="scope">-->
<!--              <span>{{-->
<!--                  scope.row.settlementChannel === 1-->
<!--                      ? "自有通道"-->
<!--                      : scope.row.settlementChannel === 2-->
<!--                          ? "第三方通道"-->
<!--                          : scope.row.settlementChannel === 3-->
<!--                              ? "银联通道"-->
<!--                              : "其他"-->
<!--                }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="支付类型" width="120">-->
<!--            <template slot-scope="scope">-->
<!--              <span>{{ paymentType[scope.row.paymentType] }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="来源端" width="120">-->
<!--            <template slot-scope="scope">-->
<!--              <span>{{ sourcePort[scope.row.sourcePort] }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column align="center" label="用户付款方式" width="120">-->
<!--            <template slot-scope="scope">-->
<!--              <span v-if="scope.row.channel === 0">微信</span>-->
<!--              <span v-if="scope.row.channel === 1">支付宝</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              align="center"
              label="交易金额"
              prop="payAmount"
              width="100"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.payAmount).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="交易手续费"
              prop="fee"
              width="100"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.fee).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="交易时间" prop="payTime" width="180">
          </el-table-column>
          <el-table-column
              align="center"
              label="交易状态"
              prop="payType"
              width="120"
          >
            <template slot-scope="scope">
              <span>{{
                  scope.row.tradeStatus === 0
                      ? "未支付"
                      : (scope.row.tradeStatus === 2?"交易取消":"交易成功")
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="业务单编号" prop="parkingOrderIntoId" width="180">
          </el-table-column>
          <el-table-column label="第三方流水" prop="outTradeNo" width="220">
          </el-table-column>
<!--          <el-table-column-->
<!--              align="center"-->
<!--              label="结算方式"-->
<!--              prop="payType"-->
<!--              width="120"-->
<!--          >-->
<!--            <template slot-scope="scope">-->
<!--              <span>{{-->
<!--                  scope.row.settlementType === 1 ? "平台结算" : "独立结算"-->
<!--                }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Numplate from "@/components/Numplate";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Settlement from "@/components/Settlement";
import SuggestParking from "@/components/SuggestParking";
import {searchTrade} from "@/api/finance";
// import NumplateSuggest from "@/components/NumplateSuggest";
import {doExport} from "@/api/common";
import {dateFormat} from "@/utils/utils";

export default {
  name: "recharge-search",
  mixins: [BaseMixin, Permission],
  data() {
    const today = dateFormat("yyyy-mm-dd", new Date());
    const beginDate = today;
    // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
    const endDate = today;
    return {
      searchDate: [beginDate, endDate],
      query: {
        tenantType: "",
        plateNumber: "",
        userTel: "",
        settlementId: "",
        parkingLotId: "",
        parkingLotType: "",
        channel: "",
        settlementChannel: "",
        paymentType: "",
        sourcePort: "",
        orderNo: "",
        outTradeNo: "",
        tradeStatus: "",
        settlementType: "",
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      dataList: [],
      paymentType: {
        1: "支付",
        2: "余额",
        // 3: "虚拟优惠券",
        // 4: "代金券",
        // 5: "即停豆",
      },
      sourcePort: {
        1: "IOS",
        2: "android",
        3: "巡检充值",
        4: "其他",
        5: "app端的H5",
        6: "商户端",
        7: "用户端",
      },
      totalMoney: 0, //总金额
      feeAmount: 0,
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    Numplate,
    // Settlement,
    SuggestParking,
    // NumplateSuggest,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.searchDate = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.searchDate && this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0] //  + " 00:00:00";
        params["endDate"] = this.searchDate[1] //  + " 23:59:59";
      }
      const res = await searchTrade(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.records;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.form.exportSize = returnObject.total;
        this.totalMoney = returnObject.totalAmount;
        this.feeAmount = returnObject.feeAmount;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.searchDate && this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0] //  + " 00:00:00";
        params["endDate"] = this.searchDate[1] //  + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/MoneyManagement/searchTrade", params, "交易查询.xlsx");
      // window.open("http://172.16.69.129:81" + "/MoneyManagement/searchTrade?" + objectToQueryString(params));
      loading.close();
      this.exportDialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
